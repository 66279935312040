import { transform } from "@babel/standalone";
import beautify from "js-beautify";
import * as R from "ramda";
import * as neetoCommonsUtils from "@bigbinary/neeto-cist";
import "babel-polyfill";

const babelConfig = {
  filename: "transformer",
  presets: ["env"],
};

const neetoCommons = Object.assign({}, R, neetoCommonsUtils);
window.neetoCommons = neetoCommons;

// prettier-ignore
const modulesPrefix =
`const {${R.keys(neetoCommons).join(",")}} = neetoCommons;\n`
const attachModulePrefix = R.concat(modulesPrefix);
const detachModulePrefix = R.replace(modulesPrefix, "");
const disableStrict = R.replace('"use strict";\n\n', "");

const formatOutput = R.compose(
  R.join("\n\n"),
  R.map(str => (R.isNil(str) ? R.toString(str) : beautify(str)))
);
const formatError = error =>
  error
    .replace(/(?<=\s*)1\s?\|/, "") // Remove line 1
    .replace(
      /(?<=\s*)\d+\s?(?=\|)|(?<=\()\d+(?=:\d+\))/g, // Replace line numbers in the error message
      n => parseInt(n) - 1
    );

export const evalSource = input => {
  let source = input;
  let output = [];
  let error = "";

  const logger = window.console.log;
  window.console.log = function (...args) {
    output.push(JSON.stringify(args[0]));
    logger.apply(window.console, args);
  };

  source = attachModulePrefix(source);
  try {
    source = transform(source, babelConfig).code;
    source = disableStrict(source);
    output.push(`> ${JSON.stringify(eval(source))}`);
  } catch (err) {
    error = detachModulePrefix(err.message);
    error = formatError(error);
  }

  const outputStr = formatOutput(output);
  window.console.log = logger;
  return [outputStr, error];
};

export const pickRandom = array =>
  array[Math.floor(Math.random() * array.length)];
