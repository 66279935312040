import { Typography } from "@bigbinary/neetoui";

const Console = ({ output, error }) => (
  <div className="mt-2 flex flex-1 flex-col">
    <Typography style="h5" weight="bold">
      CONSOLE:
    </Typography>
    <div className="relative mt-4 flex-1">
      <div className="absolute h-full w-full overflow-auto bg-black px-2 py-3 text-white">
        <Typography style="body1" component="code">
          <pre>
            {output}
            {error && <p className="text-red-500">{error}</p>}
          </pre>
        </Typography>
      </div>
    </div>
  </div>
);
export default Console;
