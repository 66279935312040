import { Plus } from "@bigbinary/neeto-icons";
import { Pane, Typography, Button } from "@bigbinary/neetoui";
import { useMonaco } from "@monaco-editor/react";

import data from "./sample.json";
import { pickRandom } from "./utils";

const ActionPane = ({ editorRef, isOpen, onClose }) => {
  const monaco = useMonaco();

  const insertText = text => {
    const editor = editorRef.current;
    if (!editor) return;
    const pos = editor.getPosition();
    const range = new monaco.Range(
      pos.lineNumber,
      pos.column,
      pos.lineNumber,
      pos.column
    );
    editor.executeEdits("", [{ range, text }]);
    onClose();
  };

  const actions = [
    {
      label: "Insert sample list",
      onClick: () => insertText(JSON.stringify(data)),
    },
    {
      label: "Insert sample dictionary",
      onClick: () => insertText(JSON.stringify(pickRandom(data))),
    },
  ];

  return (
    <Pane isOpen={isOpen} onClose={onClose}>
      <Pane.Header>
        <Typography style="h2">Quick actions</Typography>
      </Pane.Header>
      <Pane.Body>
        {actions.map(props => (
          <Button
            style="text"
            iconPosition="left"
            icon={Plus}
            key={props.label}
            {...props}
          />
        ))}
      </Pane.Body>
    </Pane>
  );
};

export default ActionPane;
