import { forwardRef, useRef } from "react";
import MonacoEditor from "@monaco-editor/react";
import beautify from "js-beautify";
import { Button } from "@bigbinary/neetoui";
import { Flash } from "@bigbinary/neeto-icons";

const Editor = ({ onChange }, forwardedRef) => {
  const ref = useRef();

  const formatContent = () => {
    const editor = ref.current;
    if (!ref) return;

    try {
      let content = editor.getValue();
      content = beautify(content);
      editor.setValue(content);
    } catch {}
  };

  return (
    <div className="flex w-3/5 flex-col">
      <div className="flex justify-end px-6 pb-3">
        <Button
          icon={Flash}
          label="Pretty"
          style="secondary"
          iconPosition="left"
          onClick={formatContent}
        />
      </div>
      <div className="flex-1">
        <MonacoEditor
          language="javascript"
          options={{
            autoIndent: "advanced",
            autoClosingBrackets: "languageDefined",
            fontSize: 16,
            minimap: { enabled: false },
            scrollbar: {
              vertical: "hidden",
              horizontal: "hidden",
            },
          }}
          onChange={onChange}
          onMount={editor => {
            ref.current = editor;
            if (forwardedRef) forwardedRef.current = editor;
          }}
        />
      </div>
    </div>
  );
};

export default forwardRef(Editor);
