import { useRef, useState } from "react";
import { useFuncDebounce } from "@bigbinary/neeto-commons-frontend/react-utils";

import ActionPane from "./ActionPane";
import Header from "./Header";
import Editor from "./Editor";
import Console from "./Console";
import { evalSource } from "./utils";

const initialState = {
  isLoading: false,
  error: "",
  output: "",
};

function App() {
  const [replState, setReplState] = useState(initialState);
  const [isActionPaneOpen, setIsActionPaneOpen] = useState(false);
  const editorRef = useRef();

  const executeEditorValue = useFuncDebounce(value => {
    if (!value) return setReplState(initialState);
    setReplState({ ...initialState, isLoading: true });
    const [output, error] = evalSource(value);
    setReplState({ isLoading: false, error, output });
  }, 2000);

  const { isLoading, output, error } = replState;

  return (
    <>
      <div className="box-border flex h-screen w-screen flex-col py-4 px-2">
        <Header
          isLoading={isLoading}
          onOpenActions={() => setIsActionPaneOpen(true)}
        />
        <div className="flex flex-1">
          <Editor ref={editorRef} onChange={executeEditorValue} />
          <Console output={output} error={error} />
        </div>
      </div>

      <ActionPane
        editorRef={editorRef}
        isOpen={isActionPaneOpen}
        onClose={() => setIsActionPaneOpen(false)}
      />
    </>
  );
}

export default App;
