import { Button, Spinner, Typography } from "@bigbinary/neetoui";
import { ExternalLink, SettingsChecked } from "@bigbinary/neeto-icons";

const Header = ({ isLoading, onOpenActions }) => (
  <div className="flex justify-between">
    <div className="ml-3">
      <Typography style="h3" weight="bold" component="h3">
        neetoCommons
      </Typography>
      <Typography
        style="h1"
        weight="black"
        component="h1"
        className="tracking-widest"
      >
        REPL
      </Typography>
    </div>
    <div className="space-x-4">
      {isLoading && (
        <div className="flex items-center space-x-2">
          <Typography style="body3">Running</Typography>
          <Spinner />
        </div>
      )}
      <Button
        style="link"
        size="small"
        label="Documentation"
        icon={ExternalLink}
        href="https://github.com/bigbinary/neeto-cist#readme"
        target="_blank"
      />
      <Button
        size="small"
        style="text"
        icon={SettingsChecked}
        label="Quick Actions"
        onClick={onOpenActions}
      />
    </div>
  </div>
);

export default Header;
